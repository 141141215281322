import React, { useState, useEffect } from 'react';

const NothingForSale = () => {
  const [clickCount, setClickCount] = useState(0);
  const [clickedButton, setClickedButton] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  // Auto-scroll logic with 3000ms interval
  useEffect(() => {
    const interval = setInterval(() => {
      setClickCount((prevCount) => prevCount + 1);
    }, 3000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const handleSellClick = () => {
    setClickedButton('sell');
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 2500);
  };

  const getQuestionText = () => {
    const questions = [
      "Hmm, maybe I'll buy your nothing.",
      "Tell me more about this 'nothing' you're selling.",
      "Is your nothing better than other nothings?",
      "Can I sample this nothing before buying?",
      "How much does your nothing cost?",
      "Is your nothing refundable?",
      "Do you offer premium versions of nothing?",
      "Can I get a bulk discount on nothing?",
      "Is your nothing eco-friendly?",
      "Does your nothing come with a warranty?"
    ];
    return questions[clickCount % questions.length];
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: 'black',
      color: 'white',
      padding: '2rem',
      fontFamily: 'Arial, sans-serif'
    }}>
      <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>So, You're Selling Nothing?</h1>
      <p style={{ fontSize: '1.25rem', marginBottom: '2rem' }}>Let me ask you a few questions before I make a decision...</p>
      
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '1rem', marginBottom: '2rem', width: '100%', maxWidth: '600px' }}>
        <button 
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: 'black',
            color: 'white',
            border: '1px solid white',
            borderRadius: '0.25rem',
            cursor: 'pointer',
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {getQuestionText()}
        </button>
      </div>

      <button 
        onClick={handleSellClick}
        style={{
          padding: '0.75rem 2rem',
          backgroundColor: '#ff5722',
          color: 'white',
          border: 'none',
          borderRadius: '0.25rem',
          cursor: 'pointer',
          fontSize: '1.25rem',
          fontWeight: 'bold',
          marginBottom: '2rem'
        }}
      >
        Sell
      </button>

      {showAlert && clickedButton === 'sell' && (
        <div style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '1rem',
          borderRadius: '0.25rem',
          marginBottom: '1rem',
          maxWidth: '400px'
        }}>
          <h4 style={{ marginTop: 0 }}>Nice try!</h4>
          <p style={{ marginBottom: 0 }}>
            {clickCount === 0 && "Wait, you're trying to sell me... nothing? Bold strategy!"}
            {clickCount === 1 && "Nothing for sale, huh? Sounds like you're really putting in the effort."}
            {clickCount === 2 && "Selling nothing? You might just be onto something... or rather, nothing."}
            {clickCount === 3 && "You seriously expect me to pay for nothing? Well played."}
            {clickCount === 4 && "Good luck selling nothing—at least you won’t have to handle returns!"}
            {clickCount === 5 && "Refundable? I can't refund what doesn’t exist."}
            {clickCount === 6 && "Premium nothing? Wow, you really know how to sell nothing."}
            {clickCount === 7 && "Bulk nothing? That’s a lot of... nothing."}
            {clickCount === 8 && "Eco-friendly nothing? Well, it does leave zero footprint."}
            {clickCount === 9 && "A warranty on nothing? At least it won’t break!"}
          </p>
        </div>
      )}

      <p style={{ fontSize: '0.875rem', fontStyle: 'italic', marginTop: '2rem' }}>
        Note to self: Why am I even bothering trying to sell nothing?
      </p>
    </div>
  );
};

export default NothingForSale;
